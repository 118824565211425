import React, { useState, useEffect } from 'react';
import { Footer } from '../../components';
import { TopHeader, LayoutHeader } from '../header/Header';
import './side-nav-inner-toolbar.scss';

export default function SideNavInnerToolbar({ children }) {
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    setFadeIn(false);
  }, []);

  return (
    <div className={'side-nav-inner-toolbar'}>
      <div id={'fw-content-container'} className={'container'}>
        <div className={`fade ${fadeIn ? 'active' : ''}`}></div>
        <div className={'content-header'}>
          {React.Children.map(children, (item) => {
            return item.type === TopHeader  && item;
          })}
        </div>        
        <div className={'layout-header'}>
          {React.Children.map(children, (item) => {
            return item.type === LayoutHeader  && item;
          })}
        </div>         
        <div className={'content-container'}>
          <div className={'content'}>
            {React.Children.map(children, (item) => {
              return item.type !== Footer && item.type !== TopHeader && item.type !== LayoutHeader && item;
            })}
          </div>
          <div className={'content-block'}>
            {React.Children.map(children, (item) => {
              return item.type === Footer && item;
            })}
          </div>          
        </div>
      </div>
    </div>
  );
}

