import React, { useCallback, useState } from 'react';
import logoImg from '../../../assets/comp-logo-intr.png';
import { PopupTerm } from '../popup/popup';
import { Button } from 'devextreme-react';
import { tempTermData } from '../../../../utils/terms';
import './footer.scss';

export default function Footer() {
    const [termContent, setTermContent] = useState(null);
    const [termPopupVisible, setTermPopupVisible] = useState(false);
    const termPopupHide = useCallback(() => setTermPopupVisible(false), []);
    const termPopupShow = useCallback((e) => {
        setTermContent(e);
        setTermPopupVisible(true)
    }, []);

    return (<footer className={'footer'}>
        <div className='fw-footer-header'>
            <div className='fw-footer-header-title'>MIRAECNL</div>
            <div className='fw-footer-header-detail'>경기도 화성시 동탄기흥로 560 동익미라벨타워 201A호</div>
            <div className='fw-footer-header-detail'>대표: 김민철 | 사업자등록번호: 124-81-76654</div>
            <div className='fw-footer-header-detail'>대표전화: 031-373-8831</div>
            <div className='fw-footer-header-service-numb'>
                <div className='fw-footer-header-service'>
                    <div className='service-title'>고객센터</div>
                    <div className='service-detail'>043-835-8800</div>
                </div>
                <div className='fw-footer-header-service'>
                    <div className='service-title'>A/S문의</div>
                    <div className='service-detail'>043-835-8888</div>
                </div>
            </div>
            <div className='fw-footer-header-service-time'>
                <div className='service-title'>운영시간</div>
                <div className='service-detail'>(월~금) 08:30 ~ 17:30 / (토,일, 공휴일) 휴무</div>
            </div>
            <div className='fw-footer-footer'>
                <div className='fw-footer-term'>
                    {tempTermData && tempTermData.length > 0 && tempTermData.map((item, index) => (
                        <Button className='fw-term-link' text={item.term_name || '-'} onClick={() => termPopupShow(item.term_memo)} key={index} />
                    ))}
                </div>
                <div className='fw-footer-copy-text'>Copyright © 2024 All rights reserved | MCNL</div>                
            </div>
        </div>
        <PopupTerm
            title={'약관 사항 확인'}
            width={800}
            height={600}
            visible={termPopupVisible}
            hiding={termPopupHide}
        >
            {termContent && <div className='term-memo' dangerouslySetInnerHTML={{ __html: termContent }}></div>}
        </PopupTerm>
    </footer>
    )
}
