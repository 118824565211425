import { AccountPanel } from "../../../script/components";
import { intrComp, suppComp } from "../../../../utils/default-comp";
import { ContentHeader } from "../../../script/components/header/Header";
import { useAuth } from "../../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import { FormFindLink } from "../../../script/components/account-panel/FormFind-link";

export default function AuthFind() {
    const { user } = useAuth();
    const navigate = useNavigate();

    if (user) { navigate('/home') }

    return (
        <>
            <ContentHeader
                root={'home'}
                rootLink={'/home'}
                path={['회원서비스', '아이디/비밀번호찾기']}
            />
            <AccountPanel title="아이디/비밀번호 찾기" intrComp={intrComp} suppComp={suppComp} >
                <FormFindLink joinUrls={'http://112.186.93.31:9070/app/member/find_id'}/>
            </AccountPanel>
        </>
    )
}