import { AccountFormButton } from "./account-panel-item";
import { Link } from "react-router-dom";

export function FormJoinLink({ joinUrls }) {
    const handleJoinClick = () => {
        const features = 'width=600,height=700,resizable=yes,scrollbars=yes,status=no';
        window.open(joinUrls, '_blank', features);
      };

    return (
        <div className='auth-temp-container'>
            <div className='auth-temp-mess'>
                회원가입은 미래CNL 앱에서 진행됩니다. <br />
                아래 버튼을 클릭해 회원가입 페이지로 이동하세요.
            </div>            
            <AccountFormButton
                text={'회원가입'}
                className={'first-btn'}
                type={'default'}
                onClick={handleJoinClick}
            />
            <div className={'link'}>
                계정이 있으신가요?<Link to={'/auth/login'}>로그인</Link>
            </div>            
        </div>
    )
}