import { 
    Home,
    CompCeosMess, CompBusiness, CompHistorys, CompRecruits, 
    ChrgInfoIntr, ChrgInfoStat, ChrgPaysStat, ChrgInstInqu, 
    BoarNotiStat, BoarNewsStat, BoarQnasStat, BoarAserInqu,
    UserInfostat, UserUsagstat,
    AuthLogin, AuthJoins, AuthFind
} from './focuswin/pages/cms/index'
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    { path: '/home', element: Home },
    { path: '/comp/ceosmess', element: CompCeosMess },
    { path: '/comp/business', element: CompBusiness },
    { path: '/comp/historys', element: CompHistorys },
    { path: '/comp/recruits', element: CompRecruits },
    { path: '/chrg/infointr', element: ChrgInfoIntr },
    { path: '/chrg/infostat', element: ChrgInfoStat },
    { path: '/chrg/paysstat', element: ChrgPaysStat },
    { path: '/chrg/instinqu', element: ChrgInstInqu },
    { path: '/boar/notistat', element: BoarNotiStat },
    { path: '/boar/newsstat', element: BoarNewsStat },
    { path: '/boar/qnasstat', element: BoarQnasStat },
    { path: '/boar/aserinqu', element: BoarAserInqu },
    { path: '/user/infostat', element: UserInfostat },
    { path: '/user/usagstat', element: UserUsagstat },
    { path: '/auth/login', element: AuthLogin },
    { path: '/auth/joins', element: AuthJoins },  
    { path: '/auth/find', element: AuthFind },  
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});