import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { intrComp } from '../../../../utils/default-comp';
import { useAuth } from '../../../../contexts/auth';
import { Button } from 'devextreme-react';
import { PopupAler } from '../popup/popup';
import './Header.scss';

function TopHeader() {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const [signoutPopupVisible, setSignoutPopupVisible] = useState(false);
  const signoutHide = useCallback(() => {
    setSignoutPopupVisible(false);
  }, []);

  const onNavToolbarClick = useCallback((act, pram) => {
    if (act === 'nav') {
      window.scrollTo(0, 0);
      navigate(pram);
    }
  }, [navigate])


  return (
    <>
      <header 
        className={'header-component top'}
      >
        <div className={'header-logo'}>
          <img className={'logo-imag'} src={intrComp?.logo} alt={`${intrComp?.name} 로고`} onClick={() => onNavToolbarClick('nav', '/home')} />
        </div>
        <div className={'header-user'}>
          {user ?
            <>
              <Button
                text='내 정보'
                stylingMode='text'
                className='header-btn'
                onClick={() => onNavToolbarClick('nav', '/user/infostat')}
              />
              <Button
                text='Q&A'
                stylingMode='text'
                className='header-btn'
                onClick={() => onNavToolbarClick('nav', '/boar/qnasstat')}
              />
              <Button
                text='로그아웃'
                stylingMode='text'
                className='header-btn'
                onClick={() => setSignoutPopupVisible(true)}
              />
            </> :
            <>
              <Button
                text='로그인'
                stylingMode='text'
                className='header-btn'
                onClick={() => onNavToolbarClick('nav', '/auth/login')}
              />
              <Button
                text='회원가입'
                stylingMode='text'
                className='header-btn'
                onClick={() => onNavToolbarClick('nav', '/auth/joins')}
              />
            </>
          }
        </div>
      </header>
      <PopupAler
        visible={signoutPopupVisible}
        hiding={signoutHide}
        confirm={signOut}
        message={'로그아웃 하시겠습니까?'}
      />
    </>
  )
}

function LayoutHeader() {
  const navigate = useNavigate();
  const [hoveredMain, setHoveredMain] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const onNavToolbarClick = useCallback((act, pram) => {
    if (act === 'nav') {
      window.scrollTo(0, 0);
      navigate(pram);
    }
  }, [navigate])

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setHoveredMain('');
  };

  const handleMouseEnterMain = (menu) => {
    setHoveredMain(menu);
  };

  return (
    <>
      <header 
        className={`header-component layout ${isHovered ? 'visible' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={'header-nav-main'} >
          <div className={`nav-main ${hoveredMain === 'comp' ? 'highlight' : ''}`} onMouseEnter={() => handleMouseEnterMain('comp')} onClick={() => onNavToolbarClick('nav', '/comp/ceosmess')}>회사소개</div>
          <div className={`nav-main ${hoveredMain === 'chrg' ? 'highlight' : ''}`} onMouseEnter={() => handleMouseEnterMain('chrg')} onClick={() => onNavToolbarClick('nav', '/chrg/infointr')}>충전인프라</div>
          <div className={`nav-main ${hoveredMain === 'boar' ? 'highlight' : ''}`} onMouseEnter={() => handleMouseEnterMain('boar')} onClick={() => onNavToolbarClick('nav', '/boar/notistat')}>게시판</div>
        </div>
        <div className={`header-nav-subs ${isHovered ? 'visible' : ''}`}>
          <div className={'nav-subs-list'} onMouseEnter={() => handleMouseEnterMain('comp')}>
            <div className={'nav-subs'} onClick={() => onNavToolbarClick('nav', '/comp/ceosmess')}>CEO 인사말</div>
            <div className={'nav-subs'} onClick={() => onNavToolbarClick('nav', '/comp/business')}>사업소개</div>
            <div className={'nav-subs'} onClick={() => onNavToolbarClick('nav', '/comp/historys')}>연혁</div>
            <div className={'nav-subs'} onClick={() => onNavToolbarClick('nav', '/comp/recruits')}>인재채용</div>
          </div>
          <div className={'nav-subs-list'} onMouseEnter={() => handleMouseEnterMain('chrg')}>
            <div className={'nav-subs'} onClick={() => onNavToolbarClick('nav', '/chrg/infointr')}>충전기 소개</div>
            <div className={'nav-subs'} onClick={() => onNavToolbarClick('nav', '/chrg/infostat')}>충전소 찾기</div>
            <div className={'nav-subs'} onClick={() => onNavToolbarClick('nav', '/chrg/paysstat')}>요금제</div>
            <div className={'nav-subs'} onClick={() => onNavToolbarClick('nav', '/chrg/instinqu')}>충전기 설치 문의</div>
          </div>
          <div className={'nav-subs-list'} onMouseEnter={() => handleMouseEnterMain('boar')}>
            <div className={'nav-subs'} onClick={() => onNavToolbarClick('nav', '/boar/notistat')}>공지사항</div>
            <div className={'nav-subs'} onClick={() => onNavToolbarClick('nav', '/boar/newsstat')}>뉴스</div>
            <div className={'nav-subs'} onClick={() => onNavToolbarClick('nav', '/boar/qnasstat')}>Q&A</div>
            <div className={'nav-subs'} onClick={() => onNavToolbarClick('nav', '/boar/aserinqu')}>A/S 신청</div>
          </div>                    
        </div>
      </header>
    </>
  )
}

function ContentHeader({ root, rootLink, path }) {
  const navigate = useNavigate();

  return (
    <div className='fw-content-header'>
      <div className='header-root' onClick={() => navigate(rootLink)}>
        <i className={`dx-icon-${root}`}></i>
      </div>
      {path && path.length > 0 &&
        <div className='header-path'>
          <div className='header-path-item'>{path[0]}&nbsp;&nbsp;▶</div>
          <div className='header-path-item'>&nbsp;&nbsp;{path[1]}</div>
        </div>
      }
    </div>
  )
}

export {
  TopHeader,
  LayoutHeader,
  ContentHeader
}